import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'

export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Welcome to <a href="#">App</a>
        </h1>

        <p className={styles.description}>
          Get started by editing{' '}
          <code className={styles.code}>pages/index.js</code>
        </p>

        <div className={styles.grid}>
          <button className={styles.card} onClick={() => alert('Signin')}>
            <h2>Sign in &rarr;</h2>
          </button>

          <button className={styles.card} onClick={() => alert('Signup')}>
            <h2>Sign up &rarr;</h2>
          </button>
        </div>
      </main>
    </div>
  )
}
